import React, { useState, useEffect } from "react";
import EventsList from "./components/eventsList";
import Header from "./components/header";
import SideDrawer from "./components/sideDrawer";
import BackdropOverlay from "./components/backdropOverlay";
import Footer from "./components/footer";

import Loader from "react-loader-spinner";
import './assets/main.css';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

const result = require("dotenv").config();
if (result.error) {
  console.log("Error: " + result.error.toString());
}

function App() {
  const [schedule, setSchedule] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  const drawerToggleClickHandler = () => {
    sideDrawerOpen.setSideDrawerOpen(!sideDrawerOpen);
  };

  useEffect(() => {
    // console.log("FETCH_REMOTE: " + process.env.REACT_APP_FETCH_REMOTE);
    // console.log("NODE_ENV: " + process.env.REACT_APP_NODE_ENV);
    // if (process.env.REACT_APP_FETCH_REMOTE == "true") {
    console.log("remote");
    if (!schedule) {
      console.log("require remote");
      remoteFetch()
        .then((res) => res.json())
        .then((data) => {
          console.log("SCHEDULE: ");
          console.log(JSON.stringify(data.data.getScheduleMerge));
          setSchedule(data.data.getScheduleMerge);
        });
    }
    // } else {
    //   console.log("local");
    //   if (!schedule) {
    //     setTimeout(() => {
    //       console.log("require local");
    //       setSchedule(require("./assets/schedule-merge.json"));
    //     }, 500);
    //   }
    // }
  });

  const loaderHTML = (
    // <div className="h-full mt-64">
    //   <div className="spinner divide-purple-700 items-center"></div>
    // </div>
    <div className="h-full mt-64 rounded-lg mx-auto w-1/2 p-10">
      <Loader
        className="mx-auto self-center w-1/3 p-0 "
        type="TailSpin"
        color="#4a5568"
        height={100}
        width={100} //3 secs
      />
      {/* <p className="text-center mt-16 text-white">Loading data ...</p> */}
    </div>
  );

  return (
    <div className="font-sans">
      <Header />
      {/* <BackdropOverlay /> */}
      {/* <SideDrawer /> */}
      <div className="pt-12 pb-6">
        {schedule ? <EventsList events={schedule.events} /> : loaderHTML}
      </div>
      {/* <Footer /> */}
    </div>
  );
}

function remoteFetch() {
  const uri = process.env.REACT_APP_SERVER + "/graphql";
  console.log("URI:" + uri);

  return new Promise((resolve) => {
    fetch(
      uri,
      {
        method: "POST",
        // mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify({
          query: `
            {
              getScheduleMerge {
                events {
                  eventId
                  picture
                  title
                  description
                  isStreaming
                  days {
                    day
                    performances {
                      performanceId
                      startTime
                      screen
                      eventId
                    }
                  }
                }
              }
            }`
        }),
      }
      // `https://schedule.cinemabergamo.it/schedule-merge`
      // {mode: cors}
      // `https://schedule.cinemabergamo.it/schedule-merge`
      // {header: {
      //   'Content-Type': 'application/json',
      // },
      // body: JSON.stringify(data),}
    )
      .then((res) => resolve(res))
      .catch((err) => console.log(`Error: ${err}`))
      .finally(console.log("Request ended"));

    console.log("0000");
  });
}

export default App;
